<template>
  <div v-if="dialog" class="relative z-50">
    <div class="fixe inset-0 bg-black bg-opacity-80"></div>
    <div class="fixed inset-0 overflow-auto">
      <div class="flex items-center justify-center h-full">
        <div
          class="bg-white rounded-10 shadow-sidebar w-full transition-all duration-300 w-max-lg m-2"
        >
          <div class="flex flex-col space-y-8 bg-primary rounded-10 p-6">
            <div
              class="text-right cursor-pointer text-lg text-white"
              @click="cancel"
            >
              <font-awesome-icon icon="fa-solid fa-xmark" />
            </div>
            <h1 class="font-medium text-sm text-white">Detail Transactions</h1>
            <div
              class="flex flex-col space-y-1 w-full items-center justify-center"
            >
              <img src="../../assets/icons/ic-success.svg" alt="" />
              <span class="font-semibold text-base text-white"
                >Transfer Amount</span
              >
              <span class="font-bold text-3xl text-white">{{
                positiveNominal(data.nominal) | toCurrency
              }}</span>
            </div>
          </div>
          <div class="px-7 py-8">
            <div class="table-auto">
              <table class="w-full">
                <!-- <tr>
                  <td class="whitespace-nowrap font-medium text-sm py-2">Transfer Amount</td>
                  <td class="whitespace-nowrap font-medium text-sm py-2">: {{ numberFormat(data.nominal) !== '' ? numberFormat(data.nominal) : 'Rp 0' }}</td>
                </tr> -->
                <tr>
                  <td class="whitespace-nowrap font-medium text-sm py-2">
                    Transaction Date
                  </td>
                  <td class="whitespace-nowrap font-medium text-sm py-2">
                    : {{ data.tanggal }}
                  </td>
                </tr>
                <tr v-if="data.tipe != ''">
                  <td class="whitespace-nowrap font-medium text-sm py-2">
                    Transaction Type
                  </td>
                  <td class="whitespace-nowrap font-medium text-sm py-2">
                    : {{ data.tipe }}
                  </td>
                </tr>
                <!-- <tr>
                  <td class="whitespace-nowrap font-medium text-sm py-2">Transaction Destination</td>
                  <td class="whitespace-nowrap font-medium text-sm py-2">: {{ data.transaksi !== '' ? data.transaksi : '. . .' }}</td>
                </tr> -->
                <tr v-if="data.reff != ''">
                  <td class="whitespace-nowrap font-medium text-sm py-2">
                    Reff
                  </td>
                  <td class="whitespace-nowrap font-medium text-sm py-2">
                    :
                    {{ data.reff !== "" ? data.reff : ". . ." }}
                  </td>
                </tr>
                <!-- <tr v-if="data.transaksi != ''">
                  <td class="whitespace-nowrap font-medium text-sm py-2">Recepient Name </td>
                  <td class="whitespace-nowrap font-medium text-sm py-2">: {{ data.transaksi !== '' ? data.transaksi : '. . .' }}</td>
                </tr> -->
                <tr>
                  <td class="whitespace-nowrap font-medium text-sm py-2">
                    Note
                  </td>
                  <td class="whitespace-nowrap font-medium text-sm py-2">
                    :
                    {{ data.ket != "" ? data.ket : "-" }}
                  </td>
                </tr>
                <tr v-if="data.invoice != null && data.invoice != ''">
                  <td class="whitespace-nowrap font-medium text-sm py-2">
                    Booking Id
                  </td>
                  <td class="whitespace-nowrap font-medium text-sm py-2">
                    :
                    {{ data.invoice != "" ? data.invoice : ". . ." }}
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      dialog: false,
      data: {},
    };
  },
  methods: {
    positiveNominal: (num) => Math.abs(num),
    open({ data }) {
      this.data = data ?? {};
      this.dialog = true;

      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },
    use() {
      this.resolvePromise(true);
      this.dialog = false;
    },
    cancel() {
      this.resolvePromise(false);
      this.dialog = false;
    },
  },
};
</script>
